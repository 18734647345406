<template>
  <div>
    <div class="tips">

      <div class="tip-text"><img src="@/assets/image/apply/icon_tips.png"/> 为了您的账户安全，请点击“获取验证码”，进行短信验证，完成认证！</div>
    </div>
    <div class="verify-content">
      <van-field label="手机号"
                 v-model="phone"
                 center
                 disabled="disabled"
                 placeholder="请输入手机号"
                 :border="true"/>
      <van-field v-model="sms"
                 center
                 clearable
                 required
                 label="验证码"
                 placeholder="请输入验证码"
                 :border="true"
                 use-button-slot>
        <van-button slot="button"
                    size="small"
                    type="plain"
                    class="sms-button"
                    @click="gainCode">{{ time }}
        </van-button>
      </van-field>
    </div>
    <div class="split-line"></div>
    <button class="confirm-button" @click="confirmVerify">确&emsp;定</button>
  </div>
</template>

<script>
export default {
  name: "msgverify",
  data() {
    return {
      time: '获取验证码',
      phone: '',
      sms: "",
      isClick: true,
      draftId: "",
    }
  },
  methods: {
    // 获取验证码
    async gainCode() {
      if (this.isClick) {
        if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone)) {
          const res = await this.sendVerifyCode({phone: this.phone})  // 获取验证码接口
          this.isClick = false
          let s = 60
          this.time = s + 's'
          let interval = setInterval(() => {
            s--
            this.time = s + 's'
            if (s < 0) {
              this.time = '重新获取'
              this.isClick = true
              clearInterval(interval)
            }
          }, 1000)
        } else {
          this.$message.error('请输入正确的手机号码')
        }
      }
    },
    sendVerifyCode() {

      let _this = this
      let params = {
        "draftId": this.draftId,
      }
      Http.post('/frontend/merchant/getBankPhoneCaptcha', params).then(result => {
        if (result.code === 200) {
          _this.$message.success("获取验证码成功！")

        } else {
          _this.$message.error(result.message || "验证失败，请重试！")
        }
      }).catch(error => {
        // console.log(error)
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    confirmVerify() {
      let _this = this
      let params = {
        "amount": "",
        "code": this.sms,
        "draftId": this.draftId,
      }
      Http.post('/frontend/merchant/verifyMessageCodeAndRandomAmount', params).then(result => {
        if (result.code === 200) {
          _this.$message.success("验证成功！")
          _this.$router.push({path: "/h5/applying"})
        } else {
          _this.$message.error(result.message || "验证失败，请重试！")
        }
      }).catch(error => {
        // console.log(error)
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    getDraftInfo() {
      let request_uri = '/frontend/merchant/getMerchantDraft'
      let params = {
        draftId: this.draftId,
      }

      let _this = this;
      Http.post(request_uri, params).then(result => {
        if (result.code === 200) {
          _this.phone = result.data.acctPhone
        }
      }).catch(error => {

      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },


  },
  mounted() {
    this.draftId = localStorage.getItem("draftId")
    this.getDraftInfo()
  },
  created() {
    document.title = "短信验证"
  },

}
</script>

<style scoped>
.tips {
  width: 92vw;
  margin-top: 20px;
  margin-left: 4vw;
  height: 118px;
  background: #FFFCF2;
  border: 1px solid rgba(166, 132, 31, 0.28);
  border-radius: 8px;
}

.tip-text {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 36px;
  line-height: 24px;
  font-size: 14px;
}

.tips img {
  width: 20px;
  height: 20px;
}

.verify-content {
  height: 150px;
  padding-top: 23px;
}

.sms-button {
  border: 1px solid #F6F6F6;
}

.split-line {
  width: 92vw;
  margin-left: 4vw;
  height: 1px;
  background: #9A9A9A;
  opacity: 0.4;
  margin-bottom: 40px;

}

.confirm-button {
  width: 61.2vw;
  margin-left: 19.4vw;
  margin-top: 280px;
  color: #FFFFFF;
  height: 40px;
  background: #E9BE3E;
  border: 1px solid #E9BE3E;
  border-radius: 20px;
}

/deep/ .van-field__control:disabled {
  color: #323233;
  -webkit-text-fill-color: #323233;
}

/deep/ .van-field--disabled .van-field__label {
  color: #323233;
}

</style>